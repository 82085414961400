import { Injectable, effect, signal } from '@angular/core'
import { Subject } from 'rxjs'

export type MenuMode =
  | 'static'
  | 'overlay'
  | 'horizontal'
  | 'slim'
  | 'slim-plus'
  | 'reveal'
  | 'drawer'

export type ColorScheme = 'light' | 'dark'

export type MenuColorScheme = 'colorScheme' | 'primaryColor' | 'transparent'

export interface AppConfig {
  inputStyle: string
  colorScheme: ColorScheme
  theme: string
  ripple: boolean
  menuMode: MenuMode
  scale: number
  menuTheme: MenuColorScheme
}

interface LayoutState {
  staticMenuDesktopInactive: boolean
  overlayMenuActive: boolean
  profileSidebarVisible: boolean
  configSidebarVisible: boolean
  staticMenuMobileActive: boolean
  menuHoverActive: boolean
  sidebarActive: boolean
  anchored: boolean
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  _config: AppConfig = {
    ripple: false,
    inputStyle: 'outlined',
    menuMode: 'static',
    colorScheme: 'light',
    theme: 'orange',
    scale: 14,
    menuTheme: 'colorScheme',
  }

  config = signal<AppConfig>(this._config)

  state: LayoutState = {
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    sidebarActive: false,
    anchored: false,
  }

  private configUpdate = new Subject<AppConfig>()

  private overlayOpen = new Subject<any>()

  configUpdate$ = this.configUpdate.asObservable()

  overlayOpen$ = this.overlayOpen.asObservable()

  constructor() {
    this.loadConfig()

    effect(() => {
      const config = this.config()
      if (this.updateStyle(config)) {
        this.changeTheme()
      }
      this.changeScale(config.scale)
      this.onConfigUpdate()
      localStorage.setItem('appConfig', JSON.stringify(this.config()))
    })
  }

  private loadConfig() {
    const savedConfig = localStorage.getItem('appConfig')
    const defaultConfig: AppConfig = {
      colorScheme: 'light' as ColorScheme,
      theme: 'orange',
      menuMode: 'static' as MenuMode,
      scale: 14,
      menuTheme: 'colorScheme' as MenuColorScheme,
      inputStyle: 'outlined',
      ripple: false,
    }

    if (savedConfig) {
      try {
        const savedConfigParsed = JSON.parse(savedConfig) as Partial<AppConfig>

        const validColorScheme: ColorScheme =
          savedConfigParsed.colorScheme &&
          ['light', 'dark'].includes(savedConfigParsed.colorScheme)
            ? savedConfigParsed.colorScheme
            : defaultConfig.colorScheme

        const validTheme: string =
          savedConfigParsed.theme === 'orange'
            ? savedConfigParsed.theme
            : defaultConfig.theme

        const validMenuMode: MenuMode =
          savedConfigParsed.menuMode &&
          ['static', 'overlay', 'slim', 'horizontal'].includes(
            savedConfigParsed.menuMode
          )
            ? savedConfigParsed.menuMode
            : defaultConfig.menuMode

        const validMenuTheme: MenuColorScheme =
          savedConfigParsed.menuTheme &&
          ['colorScheme', 'customScheme'].includes(savedConfigParsed.menuTheme)
            ? savedConfigParsed.menuTheme
            : defaultConfig.menuTheme

        this.config.update((config) => ({
          ...config,
          colorScheme: validColorScheme,
          theme: validTheme,
          menuMode: validMenuMode,
          scale: savedConfigParsed.scale ?? defaultConfig.scale,
          menuTheme: validMenuTheme,
          inputStyle: savedConfigParsed.inputStyle ?? defaultConfig.inputStyle,
          ripple: savedConfigParsed.ripple ?? defaultConfig.ripple,
        }))
      } catch (error) {
        console.error('Failed to parse saved config:', error)
        this.config.update((config) => ({
          ...config,
          ...defaultConfig,
        }))
      }
    } else {
      this.config.update((config) => ({
        ...config,
        ...defaultConfig,
      }))
    }
  }

  updateStyle(config: AppConfig) {
    return (
      config.theme !== this._config.theme ||
      config.colorScheme !== this._config.colorScheme
    )
  }

  onMenuToggle() {
    if (this.isOverlay()) {
      this.state.overlayMenuActive = !this.state.overlayMenuActive

      if (this.state.overlayMenuActive) {
        this.overlayOpen.next(null)
      }
    }

    if (this.isDesktop()) {
      this.state.staticMenuDesktopInactive =
        !this.state.staticMenuDesktopInactive
    } else {
      this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive

      if (this.state.staticMenuMobileActive) {
        this.overlayOpen.next(null)
      }
    }
  }

  onOverlaySubmenuOpen() {
    this.overlayOpen.next(null)
  }

  showProfileSidebar() {
    this.state.profileSidebarVisible = true
  }

  showConfigSidebar() {
    this.state.configSidebarVisible = true
  }

  isOverlay() {
    return this.config().menuMode === 'overlay'
  }

  isDesktop() {
    return window.innerWidth > 991
  }

  isSlim() {
    return this.config().menuMode === 'slim'
  }

  isSlimPlus() {
    return this.config().menuMode === 'slim-plus'
  }

  isHorizontal() {
    return this.config().menuMode === 'horizontal'
  }

  isMobile() {
    return !this.isDesktop()
  }

  onConfigUpdate() {
    this._config = { ...this.config() }
    this.configUpdate.next(this.config())
  }

  changeTheme() {
    const config = this.config()
    const themeLink = <HTMLLinkElement>document.getElementById('theme-link')
    const themeLinkHref = themeLink.getAttribute('href')!
    const newHref = themeLinkHref
      .split('/')
      .map((el) =>
        el == this._config.theme
          ? (el = config.theme)
          : el == `theme-${this._config.colorScheme}`
          ? (el = `theme-${config.colorScheme}`)
          : el
      )
      .join('/')

    this.replaceThemeLink(newHref)
  }

  replaceThemeLink(href: string) {
    const id = 'theme-link'
    let themeLink = <HTMLLinkElement>document.getElementById(id)
    const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true)

    cloneLinkElement.setAttribute('href', href)
    cloneLinkElement.setAttribute('id', id + '-clone')

    themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling)
    cloneLinkElement.addEventListener('load', () => {
      themeLink.remove()
      cloneLinkElement.setAttribute('id', id)
    })
  }

  changeScale(value: number) {
    document.documentElement.style.fontSize = `${value}px`
  }
}
